import FroalaEditor from 'froala-editor/js/froala_editor.min'

// export const onChangeSocial = function () {
//   var cbTwitter = document.getElementById('cbTwitter')
//   var cbfacebook = document.getElementById('cbfacebook')
//   if (cbTwitter.checked) {
//     var twitter = document.getElementById('twitterhbox')
//     twitter.style.display = 'block'
//   } else {
//     var twitter2 = document.getElementById('twitterhbox')
//     twitter2.style.display = 'none'
//     document.getElementById('txtDefaultTweet').value = ''
//     document.getElementById('txtViaTwitter').value = ''
//     document.getElementById('txtRelatedAccounts').value = ''
//   }
//   if (cbfacebook.checked) {
//     var facebook = document.getElementById('facebookhbox')
//     facebook.style.display = 'block'
//   } else {
//     var facebook2 = document.getElementById('facebookhbox')
//     facebook2.style.display = 'none'
//     document.getElementById('txtfacebookPost').value = ''
//   }
// }

Object.assign(FroalaEditor.POPUP_TEMPLATES, {
  'socialNetworking.popup': '[_CUSTOM_LAYER_][_BUTTONS_]'
})

// Define popup buttons.
Object.assign(FroalaEditor.DEFAULTS, {
  popupButtons: ['popupSocCancel', 'popupSocOk']
})

// Define the plugin.
// The editor parameter is the current instance.
FroalaEditor.PLUGINS.socialNetworking = function (editor) {
  // The start point for your plugin.
  function initPopup () {
    var popupButtons = ''

    var template = FroalaEditor.POPUP_TEMPLATES.customPopup
    if (typeof template === 'function') template = template.apply(editor)

    // Create the list of buttons.
    if (editor.opts.popupButtons.length > 1) {
      popupButtons += '<div class="fr-buttons">'
      popupButtons += editor.button.buildList(editor.opts.popupButtons)
      popupButtons += '</div>'
    }

    var content = `
    <header class="v-sheet v-sheet--tile theme--light v-toolbar v-toolbar--flat v-app-bar success">
       <div class="v-toolbar__title hidden-sm-and-down font-italic" style="padding-left:10px;padding-top: 3px;padding-bottom:3px;color:white">
          Share With Social Networking Sites
       </div>
    </header>
    <div id="helpMsg" style="padding:5px"><p>Share message to Social Network allows you to insert a link in your mailing<br/>that when clicked by a contact would allow them to post this message to<br/>their preferred social networking site.</p><p>The Twitter Default text and Facebook description must contain text if checked.</p>
      <div id="tab-socialMedia">
        <input id="cbTwitter" type="checkbox" onChange="document.getElementById('twitterhbox').style.display=this.checked?'block':'none'"> Twitter <br />
        <div id="twitterhbox" style="margin:5px;display:none">
          <label style="font-size: .8em" for="txtDefaultTweet">Default Tweet: 280 character limit</label>
          <textarea tabIndex=1 id="txtDefaultTweet" maxLength="280" style="width:100%;border-bottom:1px solid gray" rows="2"></textarea>
          <table style="width:100%">
            <tr>
               <td><label style="font-size: .8em;">Add Twitter Via</label></td>
               <td><label style="font-size: .8em;">Related Accounts</label></td>
            <tr>
            <tr>
              <td><input type="text" tabIndex=2 id="txtViaTwitter" style="width:80%;border-bottom:1px solid gray"/></td>
              <td><input type="text" tabIndex=3 id="txtRelatedAccounts" style="width:80%;border-bottom:1px solid gray"/></td>
            </tr>
          </table>
        </div>
        <input id="cbfacebook" type="checkbox" onChange="document.getElementById('facebookhbox').style.display=this.checked?'block':'none'"> Facebook<br />
        <div id="facebookhbox" style="margin:5px;display:none">
           <label style="font-size: .8em">Description: 1,000 character limit</label>
           <textarea tabIndex=4 id="txtfacebookPost" maxLength="280" style="width:100%;border-bottom:1px solid gray" rows="2"></textarea>
        </div>
        <input id="cblinkedin" type="checkbox"> Linked in<br />
        <div style="margin: 5px;">
          <label style="font-size: .8em">Add Social Networking Text</label>
          <input tabIndex=5 type="text" id="txtSocial" style="width:100%;border-bottom:1px solid gray" />
        </div>
      </div>
    </div>
    `
    // Load popup template.
    var popupTemplate = {
      custom_layer: content,
      buttons: popupButtons
    }

    // Create popup.
    var $popup = editor.popups.create('socialNetworking.popup', popupTemplate)

    // Assign refresh handler.
    // editor.popups.onRefresh('socialNetworking.popup', function () {
    //   console.log('refresh')
    // })

    // // Assign hide handler.
    // editor.popups.onHide('socialNetworking.popup', function () {
    //   console.log('hide')
    // })

    return $popup
  }

  function showPopup () {
    // Get the popup object defined above.
    var $popup = editor.popups.get('socialNetworking.popup')

    // If popup doesn't exist then create it.
    // To improve performance it is best to create the popup when it is first needed
    // and not when the editor is initialized.
    if (!$popup) $popup = initPopup()

    // Set the editor toolbar as the popup's container.
    editor.popups.setContainer('socialNetworking.popup', editor.$tb)

    // If the editor is not displayed when a toolbar button is pressed, then set BODY as the popup's container.
    // editor.popups.setContainer('customPlugin.popup', $('body'));

    // This will trigger the refresh event assigned to the popup.
    // editor.popups.refresh('customPlugin.popup');

    // This custom popup is displayed by pressing a button from the editor's toolbar.
    // Get the button's jQuery object in order to place the popup relative to it.
    var $btn = editor.$tb.find('.fr-command[data-cmd="socialDialog"]')

    // Set the popup's position.
    var left = $btn.offset().left
    var top =
      $btn.offset().top +
      (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10)

    // Show the custom popup.
    // The button's outerHeight is required in case the popup needs to be displayed above it.
    editor.popups.show('socialNetworking.popup', left, top, $btn.outerHeight())
  }

  function hidePopup () {
    // Hide the custom popup.
    var $popup = editor.popups.get('socialNetworking.popup')
    $popup.find('#txtDefaultTweet').val('')
    $popup.find('#txtSocial').val('')
    $popup.find('#txtViaTwitter').val('')
    $popup.find('#txtRelatedAccounts').val('')
    $popup.find('#txtfacebookPost').val('')
    $popup.find('#cbTwitter').attr('checked', false)
    $popup.find('#cbfacebook').attr('checked', false)
    $popup.find('#cblinkedin').attr('checked', false)
    $popup.find('#twitterhbox').hide()
    $popup.find('#facebookhbox').hide()
    editor.popups.hide('socialNetworking.popup')
  }

  function save () {
    var $popup = editor.popups.get('socialNetworking.popup')
    var twitterHtml = ''
    var fbHtml = ''
    var linkedinHtml = ''
    var cbTwitter = $popup.find('#cbTwitter').attr('checked')
    var cbfacebook = $popup.find('#cbfacebook').attr('checked')
    var cblinkedin = $popup.find('#cblinkedin').attr('checked')
    var socialNetworkingSelected = false
    var socialNetworkingShareText = $popup.find('#txtSocial').val()
    var passValidation = true
    if (cbTwitter === true) {
      socialNetworkingSelected = true
      var defaultTweet = $popup.find('#txtDefaultTweet').val()
      var twitterVia = $popup.find('#txtViaTwitter').val()
      var twitterRelatedAccounts = $popup.find('#txtRelatedAccounts').val()
      if (defaultTweet.length <= 0) {
        alert('Default Tweet is required')
        passValidation = false
      } else if (defaultTweet.length > 280) {
        alert('Default Tweet is too long, Maximum Tweet size 280 chars')
        passValidation = false
      }
      var qstparamvalue = `&text=${defaultTweet}&via=${twitterVia}&related=${twitterRelatedAccounts}`
      qstparamvalue = encodeURI(qstparamvalue)
      twitterHtml = `<a href="#SPSNCLICK" name="Twitter_img" qstparam="${qstparamvalue}" target ="_blank" xt="SPSNCLICK" xtsn="TW"><img alt="" id="social_network_image" src="https://contentz.mkt1699.com/ui/images/social_network/sn_twitterT.gif" style="border-right: medium none; padding-right: 0px; border-top: medium none; padding-left: 0px; padding-bottom: 3px; vertical-align: middle; border-left: medium none; padding-top: 0px; border-bottom: medium none" /></a>&nbsp;<a href="#SPSNCLICK" name="Twitter" qstparam="${qstparamvalue}" target ="_blank" xt="SPSNCLICK" xtsn="TW">Twitter</a>&nbsp;&nbsp;&nbsp;&nbsp;`
    }
    if (cbfacebook === true) {
      socialNetworkingSelected = true
      var fbPost = $popup.find('#txtfacebookPost').val()
      if (fbPost.length <= 0) {
        alert('Facebook Description is required')
        passValidation = false
      } else if (fbPost.length > 1000) {
        alert('Facebook Post is too long, Maximum size allowed is 1000 chars')
        passValidation = false
      }
      // fbPost = encodeURI(fbPost)
      fbHtml = `<a description="${fbPost}" href="#SPSNCLICK" name="Facebook_img" target="_blank" xt="SPSNCLICK" xtsn="FA"><img alt="" id="social_network_image" src="https://contentz.mkt1699.com/ui/images/social_network/sn_facebook.gif" style="border-right: medium none; padding-right: 0px; border-top: medium none; padding-left: 0px; padding-bottom: 3px; vertical-align: middle; border-left: medium none; padding-top: 0px; border-bottom: medium none" /></a>&nbsp;<a description="${fbPost}" href="#SPSNCLICK" name="Facebook" target="_blank" xt="SPSNCLICK" xtsn="FA">Facebook</a>&nbsp;&nbsp;&nbsp;&nbsp;`
    }
    if (cblinkedin === true) {
      socialNetworkingSelected = true
      linkedinHtml = '<a href="#SPSNCLICK" name="LinkedIn_img" target="_blank" xt="SPSNCLICK" xtsn="LI"><img alt="" id="social_network_image"  src="https://contentz.mkt1699.com/ui/images/social_network/sn_linkedin.gif" style="border-right: medium none; padding-right: 0px; border-top: medium none; padding-left: 0px; padding-bottom: 3px; vertical-align: middle; border-left: medium none; padding-top: 0px; border-bottom: medium none" /></a>&nbsp;<a href="#SPSNCLICK" name="LinkedIn" target="_blank" xt="SPSNCLICK" xtsn="LI">LinkedIn</a>'
    }
    if (socialNetworkingSelected && passValidation) {
      var html = `<span>${socialNetworkingShareText} </span>&nbsp;&nbsp;&nbsp;${twitterHtml}${fbHtml}${linkedinHtml}`
      editor.html.insert(html)
      editor.events.trigger('blur')
      $popup.find('#txtDefaultTweet').val('')
      $popup.find('#txtSocial').val('')
      $popup.find('#txtViaTwitter').val('')
      $popup.find('#txtRelatedAccounts').val('')
      $popup.find('#txtfacebookPost').val('')
      $popup.find('#cbTwitter').attr('checked', false)
      $popup.find('#cbfacebook').attr('checked', false)
      $popup.find('#cblinkedin').attr('checked', false)
      $popup.find('#twitterhbox').hide()
      $popup.find('#facebookhbox').hide()
      hidePopup()
    } else if (socialNetworkingSelected === false) {
      alert('You must select at least 1 checkbox before hitting ok.')
    }
  }

  // Expose public methods. If _init is not public then the plugin won't be initialized.
  // Public method can be accessed through the editor API:
  // editor.myPlugin.publicMethod();
  return {
    showPopup: showPopup,
    hidePopup: hidePopup,
    save: save
  }
}
FroalaEditor.ICON_DEFAULT_TEMPLATE = 'font_awesome'
FroalaEditor.DefineIconTemplate('font_awesome', '<i class="fa fa-[NAME]" aria-hidden="true"></i>')
FroalaEditor.DefineIcon('imageIcon', { NAME: 'share-alt' })
FroalaEditor.RegisterCommand('socialDialog', {
  title: 'Share With Social Networking Sites',
  icon: 'imageIcon',
  focus: false,
  undo: false,
  popup: true,
  plugin: 'socialNetworking',
  callback: function () {
    if (!this.popups.isVisible('socialNetworking.popup')) {
      this.socialNetworking.showPopup()
    } else {
      if (this.$el.find('.fr-marker')) {
        this.events.disableBlur()
        this.selection.restore()
      }
      this.popups.hide('socialNetworking.popup')
    }
  }
})

// Define custom popup button 2.
FroalaEditor.DefineIcon('popupSocOk', { NAME: 'OK', template: 'okButton' })
FroalaEditor.RegisterCommand('popupSocOk', {
  title: 'Ok',
  icon: 'popupSocOk',
  undo: false,
  focus: false,
  callback: function () {
    this.socialNetworking.save()
  }
})

FroalaEditor.DefineIcon('popupSocCancel', { NAME: 'CANCEL', template: 'cancelButton' })
FroalaEditor.RegisterCommand('popupSocCancel', {
  title: 'Cancel',
  icon: 'popupSocCancel',
  undo: false,
  focus: false,
  callback: function () {
    this.socialNetworking.hidePopup()
  }
})
