import Vue from 'vue'
// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'

// Require Froala Editor js file.
require('froala-editor/js/froala_editor.pkgd.min.js')
require('froala-editor/js/plugins/font_family.min.js')
require('froala-editor/js/plugins/font_size.min.js')
require('froala-editor/js/plugins/colors.min.js')
require('froala-editor/js/plugins/inline_class.min.js')
require('froala-editor/js/plugins/inline_style.min.js')
require('froala-editor/js/plugins/line_breaker.min.js')
require('froala-editor/js/plugins/line_height.min.js')
require('froala-editor/js/plugins/fullscreen.min.js')
require('froala-editor/js/plugins/emoticons.min.js')
require('froala-editor/js/plugins/paragraph_format.min.js')
require('froala-editor/js/plugins/paragraph_style.min.js')
require('froala-editor/js/plugins/table.min.js')
require('froala-editor/js/plugins/video.min.js')
require('froala-editor/js/plugins/forms.min.js')
require('froala-editor/js/plugins/image.min.js')
require('froala-editor/js/plugins/align.min.js')
require('froala-editor/js/plugins/lists.min.js')
require('froala-editor/js/plugins/print.min.js')
require('froala-editor/js/plugins/draggable.min.js')
require('froala-editor/js/plugins/entities.min.js')
require('froala-editor/js/plugins/quote.min.js')
require('froala-editor/js/plugins/word_paste.min.js')
require('froala-editor/js/plugins/help.min.js')
require('froala-editor/js/plugins/quick_insert.min.js')
require('froala-editor/js/plugins/special_characters.min.js')
require('froala-editor/js/plugins/image_manager.min.js')
require('froala-editor/js/plugins/char_counter.min.js')
require('froala-editor/js/plugins/code_view.min.js')
require('froala-editor/js/plugins/code_beautifier.min.js')
require('froala-editor/js/plugins/link.min.js')
require('froala-editor/js/third_party/image_tui.min.js')
require('froala-editor/js/third_party/embedly.min.js')
// require('froala-editor/js/third_party/spell_checker.min.js') - dont need it
require('@/plugins/Froala/opt-out/opt-out.js')
require('@/plugins/Froala/click-to-view/click-to-view.js')
require('@/plugins/Froala/social-networking/social-networking.js')
require('@/plugins/Froala/personalization/personalization.js')
require('@/plugins/Froala/forward-to-a-friend/forward-to-a-friend.js')
// require('@/plugins/Froala/save-image/save-image.js')

// Import Froala Editor css files.
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')
require('froala-editor/css/plugins/image.min.css')
require('froala-editor/css/plugins/fullscreen.min.css')
require('froala-editor/css/plugins/code_view.min.css')
require('froala-editor/css/plugins/table.min.css')
require('froala-editor/css/plugins/video.min.css')
require('froala-editor/css/plugins/draggable.min.css')
require('froala-editor/css/plugins/help.min.css')
require('froala-editor/css/plugins/emoticons.min.css')
require('froala-editor/css/plugins/special_characters.min.css')
require('froala-editor/css/plugins/line_breaker.min.css')
require('froala-editor/css/plugins/quick_insert.min.css')
require('froala-editor/css/third_party/image_tui.min.css')
// require('froala-editor/css/third_party/spell_checker.min.css')
require('froala-editor/css/third_party/font_awesome.min.css')
require('froala-editor/css/third_party/embedly.min.css')

Vue.use(VueFroala)
