import FroalaEditor from 'froala-editor/js/froala_editor.min'

(function (FroalaEditor) {
  Object.assign(FroalaEditor.POPUP_TEMPLATES, {
    'personalization.popup': '[_CUSTOM_LAYER_][_BUTTONS_]'
  })

  // Define popup buttons.
  Object.assign(FroalaEditor.DEFAULTS, {
    popupPERButtons: ['popupPERCancel', 'popupPEROk']
  })

  FroalaEditor.DEFAULTS = Object.assign(FroalaEditor.DEFAULTS, {
    personalizationItems: []
  })

  FroalaEditor.PLUGINS.personalization = function (editor) {
    async function initPopup () {
      var popupPERButtons = ''

      var template = FroalaEditor.POPUP_TEMPLATES.customPopup
      if (typeof template === 'function') template = template.apply(editor)
      // Create the list of buttons.
      if (editor.opts.popupPERButtons.length > 1) {
        popupPERButtons += '<div class="fr-buttons">'
        popupPERButtons += editor.button.buildList(editor.opts.popupPERButtons)
        popupPERButtons += '</div>'
      }

      var content = `
                  <header class="v-sheet v-sheet--tile theme--light v-toolbar v-toolbar--flat v-app-bar success">
                    <div class="v-toolbar__title hidden-sm-and-down font-italic " style="padding-left:10px;padding-top: 3px;padding-bottom:3px;color:white">
                        Add Personalization
                    </div>
                  </header>
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <div class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted">
                          <div class="v-input__control">
                            <div class="v-input__slot">
                              <div class="v-text-field__slot">
                                <label for="personalizedDropDown" class="v-label v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Personalizations</label>
                                <select tabIndex=1 id="personalizedDropDown" class="col-md-12">
                                  <option>FNAME</option>
                                  <option>LNAME</option>
                                  <option>MDDO</option>`
      editor.opts.personalizationItems.forEach(element => {
        content += `<option>` + element.name + `</option>`
      })
      content += `               </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  `
      var popupTemplate = {
        custom_layer: content,
        buttons: popupPERButtons
      }

      // Create popup.
      var $popup = editor.popups.create('personalization.popup', popupTemplate)
      return $popup
    }

    function showPopup () {
    // Get the popup object defined above.
      var $popup = editor.popups.get('personalization.popup')

      // If popup doesn't exist then create it.
      // To improve performance it is best to create the popup when it is first needed
      // and not when the editor is initialized.
      $popup = initPopup()

      // Set the editor toolbar as the popup's container.
      editor.popups.setContainer('personalization.popup', editor.$tb)

      // If the editor is not displayed when a toolbar button is pressed, then set BODY as the popup's container.
      // editor.popups.setContainer('customPlugin.popup', $('body'));

      // This will trigger the refresh event assigned to the popup.
      // editor.popups.refresh('customPlugin.popup');

      // This custom popup is displayed by pressing a button from the editor's toolbar.
      // Get the button's jQuery object in order to place the popup relative to it.
      var $btn = editor.$tb.find('.fr-command[data-cmd="personalizationDialog"]')

      // Set the popup's position.
      var left = $btn.offset().left
      var top =
      $btn.offset().top +
      (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10)

      // Show the custom popup.
      // The button's outerHeight is required in case the popup needs to be displayed above it.
      editor.popups.show('personalization.popup', left, top, $btn.outerHeight())
    }

    function hidePopup () {
    // Hide the custom popup.
      editor.popups.hide('personalization.popup')
    }

    function save () {
    // Save the data to the editor
      var $popup = editor.popups.get('personalization.popup')
      var personalizedDropDown = $popup.find('#personalizedDropDown')
      var personalizationElement = personalizedDropDown.val()
      var personalizationText = '%%' + personalizationElement + '%%'

      if (personalizationText.length > 0) {
        editor.html.insert(personalizationText)
        editor.events.trigger('blur')
        hidePopup()
      }
    }

    return {
      showPopup: showPopup,
      hidePopup: hidePopup,
      save: save
    }
  }
  FroalaEditor.ICON_DEFAULT_TEMPLATE = 'font_awesome'
  FroalaEditor.DefineIconTemplate('font_awesome', '<i class="fa fa-[NAME]" aria-hidden="true"></i>')
  FroalaEditor.DefineIcon('personalizationIcon', { NAME: 'pencil-square-o' })
  FroalaEditor.RegisterCommand('personalizationDialog', {
    title: 'Add Personalization',
    icon: 'personalizationIcon',
    focus: false,
    undo: false,
    popup: true,
    plugin: 'personalization',
    callback: function () {
      if (!this.popups.isVisible('personalization.popup')) {
        this.personalization.showPopup()
      } else {
        if (this.$el.find('.fr-marker')) {
          this.events.disableBlur()
          this.selection.restore()
        }
        this.popups.hide('personalization.popup')
      }
    }
  })

  FroalaEditor.DefineIcon('popupPEROk', { NAME: 'OK', template: 'okButton' })
  FroalaEditor.RegisterCommand('popupPEROk', {
    title: 'Ok',
    icon: 'popupPEROk',
    undo: false,
    focus: false,
    callback: function () {
      this.personalization.save()
      this.personalization.hidePopup()
    }
  })

  FroalaEditor.DefineIcon('popupPERCancel', { NAME: 'CANCEL', template: 'cancelButton' })
  FroalaEditor.RegisterCommand('popupPERCancel', {
    title: 'Cancel',
    icon: 'popupPERCancel',
    undo: false,
    focus: false,
    callback: function () {
      this.personalization.hidePopup()
    }
  })
})(FroalaEditor)
