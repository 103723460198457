import FroalaEditor from 'froala-editor/js/froala_editor.min'

// (function (FroalaEditor) {
Object.assign(FroalaEditor.POPUP_TEMPLATES, {
  'forwardToAFriend.popup': '[_CUSTOM_LAYER_][_BUTTONS_]'
})

// Define popup buttons.
Object.assign(FroalaEditor.DEFAULTS, {
  popupF2FButtons: ['popupF2FCancel', 'popupF2FOk']
})

FroalaEditor.DEFAULTS = Object.assign(FroalaEditor.DEFAULTS, {
  selection: ''
})

FroalaEditor.PLUGINS.forwardToAFriend = function (editor) {
  function initPopup () {
    var popupF2FButtons = ''

    var template = FroalaEditor.POPUP_TEMPLATES.customPopup
    if (typeof template === 'function') template = template.apply(editor)
    // Create the list of buttons.
    if (editor.opts.popupF2FButtons.length > 1) {
      popupF2FButtons += '<div class="fr-buttons">'
      popupF2FButtons += editor.button.buildList(editor.opts.popupF2FButtons)
      popupF2FButtons += '</div>'
    }

    var content = `
                  <header class="v-sheet v-sheet--tile theme--light v-toolbar v-toolbar--flat v-app-bar success">
                    <div class="v-toolbar__title hidden-sm-and-down font-italic " style="padding-left:10px;padding-top: 3px;padding-bottom:3px;color:white">
                      Forward to a Friend Link
                    </div>
                  </header>
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-12 col-lg-12 col-12">
                        <div class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted">
                          <div class="v-input__control">
                            <div class="v-input__slot">
                              <div class="v-text-field__slot">
                                <label for="txtForwardToAFriend" class="v-label v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Add Forward to a Friend Link</label>
                                <input type="text" required="required" tabIndex=1 id="txtForwardToAFriend" class="col-md-12" value="` + editor.opts.selection + `" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  `
    var popupTemplate = {
      custom_layer: content,
      buttons: popupF2FButtons
    }

    // Create popup.
    var $popup = editor.popups.create('forwardToAFriend.popup', popupTemplate)
    return $popup
  }

  function showPopup () {
    editor.selection.save()
    // Get the popup object defined above.
    var $popup = editor.popups.get('forwardToAFriend.popup')

    // If popup doesn't exist then create it.
    // To improve performance it is best to create the popup when it is first needed
    // and not when the editor is initialized.
    editor.opts.selection = editor.selection.get()
    $popup = initPopup()

    // Set the editor toolbar as the popup's container.
    editor.popups.setContainer('forwardToAFriend.popup', editor.$tb)

    // If the editor is not displayed when a toolbar button is pressed, then set BODY as the popup's container.
    // editor.popups.setContainer('customPlugin.popup', $('body'));

    // This will trigger the refresh event assigned to the popup.
    // editor.popups.refresh('customPlugin.popup');

    // This custom popup is displayed by pressing a button from the editor's toolbar.
    // Get the button's jQuery object in order to place the popup relative to it.
    var $btn = editor.$tb.find('.fr-command[data-cmd="forwardToAFriendDialog"]')

    // Set the popup's position.
    var left = $btn.offset().left
    var top =
      $btn.offset().top +
      (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10)

    // Show the custom popup.
    // The button's outerHeight is required in case the popup needs to be displayed above it.
    editor.popups.show('forwardToAFriend.popup', left, top, $btn.outerHeight())
  }

  function hidePopup () {
    // Hide the custom popup.
    var $popup = editor.popups.get('forwardToAFriend.popup')
    $popup.find('#txtForwardToAFriend').val('')
    editor.popups.hide('forwardToAFriend.popup')
  }

  function getHyperLinkName () {
    var fullDate = new Date()
    var twoDigitMonth = fullDate.getMonth() + 1 + ''
    if (twoDigitMonth.length === 1) twoDigitMonth = '0' + twoDigitMonth
    var twoDigitDate = fullDate.getDate() + ''
    if (twoDigitDate.length === 1) twoDigitDate = '0' + twoDigitDate

    var date = twoDigitMonth + twoDigitDate + fullDate.getFullYear()
    var name = 'Hyperlink_' + date + '_' + fullDate.getTime()
    return name
  }

  function save () {
    // Save the data to the editor
    var $popup = editor.popups.get('forwardToAFriend.popup')
    var shareWithaFriendElementText = $popup.find('#txtForwardToAFriend').val()
    if (shareWithaFriendElementText.length > 0) {
      var name = getHyperLinkName()
      var html = `<a href='#SPFORWARD' name='${name}' target='_blank' xt='SPFORWARD'>${shareWithaFriendElementText}</a>`
      editor.html.insert(html)
      editor.events.trigger('blur')
      $popup.find('#txtForwardToAFriend').val('')
      hidePopup()
    }
  }

  return {
    showPopup: showPopup,
    hidePopup: hidePopup,
    save: save
  }
}
FroalaEditor.ICON_DEFAULT_TEMPLATE = 'font_awesome'
FroalaEditor.DefineIconTemplate('font_awesome', '<i class="fa fa-[NAME]" aria-hidden="true"></i>')
FroalaEditor.DefineIcon('f2fIcon', { NAME: 'group' })
FroalaEditor.RegisterCommand('forwardToAFriendDialog', {
  title: 'Forward to a Friend Link',
  icon: 'f2fIcon',
  focus: true,
  undo: true,
  popup: true,
  refreshAfterCallback: true,
  plugin: 'forwardToAFriend',
  callback: function () {
    if (!this.popups.isVisible('forwardToAFriend.popup')) {
      this.forwardToAFriend.showPopup()
    } else {
      if (this.$el.find('.fr-marker')) {
        this.events.disableBlur()
        this.selection.restore()
      }
      this.popups.hide('forwardToAFriend.popup')
    }
  }
})

FroalaEditor.DefineIcon('popupF2FOk', { NAME: 'OK', template: 'okButton' })
FroalaEditor.RegisterCommand('popupF2FOk', {
  title: 'Ok',
  icon: 'popupF2FOk',
  undo: false,
  focus: false,
  callback: function () {
    this.forwardToAFriend.save()
    this.forwardToAFriend.hidePopup()
  }
})

FroalaEditor.DefineIcon('popupF2FCancel', { NAME: 'CANCEL', template: 'cancelButton' })
FroalaEditor.RegisterCommand('popupF2FCancel', {
  title: 'Cancel',
  icon: 'popupF2FCancel',
  undo: false,
  focus: false,
  callback: function () {
    this.forwardToAFriend.hidePopup()
  }
})
